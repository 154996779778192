/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useState, useEffect, useRef } from "react";

import ImgCrop from 'antd-img-crop';
import {
    Drawer,
    Button,
    Space,
    notification,
    Form,
    Input,
    Select,
    Upload,
    Modal,
    Spin,
    Row,
    Col,
    Badge,
    message
} from "antd";

import { PlusOutlined } from '@ant-design/icons';

// Districts
import Discricts from "../../../Raw/Districts";

// firebase
import { auth, db, storage } from '../../../firebase'
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { useSelector } from "react-redux";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const EditUserDrawer = ({ isOpen, onClose, user }) => {
    const [componentDisabled, setComponentDisabled] = useState(true);
    const formRef = useRef(null);
    const [userData, setUserData] = useState(null);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [isImageRemoved, setIsImageRemoved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [messageApi, contextHolderMessage] = message.useMessage();

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Image upload
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);

    // get user data
    useEffect(() => {
        if (user) {
            const docRef = doc(db, "users", user.uid);
            onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    setUserData(doc.data());
                } else {
                    console.log("No such document!");
                }
            }
            );
        }
    }, [isOpen]);

    useEffect(() => {
        setFormValues(userData);
    }, [isOpen, userData]);

    // set form values
    const setFormValues = (userData) => {
        if (userData && formRef.current) {
            const { fname, lname, fullname, address, contact, school, district, attempt, physicsResult, stream } = userData;
            const values = { fname, lname, fullname, address, contact, school, district, attempt, physicsResult, stream };
            formRef.current.setFieldsValue(values);
        }
    }

    useEffect(() => {
        if (userData) {
            const photoURL = userData.photoURL
            if (photoURL === "") {
                setFileList([{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://www.w3schools.com/howto/img_avatar.png',
                }])
            } else {
                setFileList([{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: photoURL,
                }])
            }
        }
    }, [userData]);


    // Image select handling functions
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const onChange = ({ fileList: newFileList }) => {
        if (fileList.length > 0) {
            newFileList.shift();
        }
        const fileType = newFileList[0]?.type;

        if (newFileList.length < 1) {
            setIsImageRemoved(true);
        } else {
            setIsImageRemoved(false);
            if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/jpeg") {
                imageTyeError();
                return;
            } else if (newFileList[0]?.size / 1024 / 1024 > 2) {
                imageSizeError();
                return;
            }
        }
        setFileList(newFileList);
        setIsImageChanged(true);
    };

    const imageTyeError = () => {
        messageApi.open({
            type: 'error',
            content: 'Image type should be png, jpg or jpeg',
        });
    };

    const imageSizeError = () => {
        messageApi.open({
            type: 'error',
            content: 'Image size should be less than 2MB',
        });
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleDrawerClose = () => {
        onClose();
        setComponentDisabled(true);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
        onFinishFailedError('error');
        setIsLoading(false);
    };

    const onFinishFailedError = (type) => {
        api[type]({
            message: { success: 'Successfully Registered', error: 'Error' }[type],
            description: {
                success: 'Please wait for the admin to approve your account',
                error: 'Please check the form again and fill the form correctly.',
            }[type],
        });
    }

    // Notifcation
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: {
                success:
                    'Profile updated successfully'
                , error: 'Error'
            }[type],
            description: {
                success: "You're successfully updated your profile, Enjoy!"
                , error: 'Something went wrong'
            }[type],
        });
    };

    // Form
    // Submit
    const onSubmit = async (values) => {
        let data = {}

        if (isImageRemoved) {
            data = {
                ...values,
                photoURL: ''
            }
        } else {
            data = {
                ...values,
                photoURL: fileList[0].originFileObj,
            };
        }

        if (isDataChanged(data) || isImageChanged) {
            updateUser(data);
        } else {
            sameData('success');
            setComponentDisabled(true);
            setIsLoading(false);
        }
    };

    const sameData = (type) => {
        api[type]({
            message: { success: 'Nothing changed', error: 'Error' }[type],
            description: {
                success: 'You have not changed anything',
                error: 'Nothing changed',
            }[type],
        });
    }

    const isDataChanged = (data) => {
        const fname = data.fname;
        const lname = data.lname;
        const fullname = data.fullname;
        const address = data.address;
        const contact = data.contact;
        const school = data.school;
        const district = typeof data.district === 'object' ? data.district.label : data.district;
        const attempt = typeof data.attempt === 'object' ? data.attempt.label : data.attempt;
        const stream = typeof data.stream === 'object' ? data.stream.label : data.stream;
        const physicsResult = typeof data.physicsResult === 'object' ? data.physicsResult.label : data.physicsResult;

        if (fname === userData.fname &&
            lname === userData.lname &&
            fullname === userData.fullname &&
            address === userData.address &&
            contact === userData.contact &&
            school === userData.school &&
            district === userData.district &&
            attempt === userData.attempt &&
            stream === userData.stream &&
            physicsResult === userData.physicsResult) {
            return false;
        } else {
            return true;
        }
    }

    // update user data
    const updateUser = async (data) => {
        setIsLoading(true);
        const fname = data.fname;
        const lname = data.lname;
        const fullname = data.fullname;
        const address = data.address;
        const contact = data.contact;
        const school = data.school;
        const district = typeof data.district === 'object' ? data.district.label : data.district;
        const attempt = typeof data.attempt === 'object' ? data.attempt.label : data.attempt;
        const stream = typeof data.stream === 'object' ? data.stream.label : data.stream;
        const physicsResult = typeof data.physicsResult === 'object' ? data.physicsResult.label : data.physicsResult;
        const photoURL = data.photoURL;

        // send data to firebase
        if (fname) {
            try {
                let image = userData.photoURL;

                // storage 
                if (isImageChanged) {
                    if (isImageRemoved) {
                        image = "";
                        // firestore update
                        const docRef = doc(db, "users", user.uid);
                        await setDoc(docRef, {
                            fname: fname,
                            lname: lname,
                            fullname: fullname,
                            email: userData.email,
                            address: address,
                            contact: contact,
                            school: school,
                            district: district,
                            attempt: attempt,
                            physicsResult: physicsResult,
                            stream: stream,
                            indexNumber: userData.indexNumber,
                            howDidYouKnow: userData.howDidYouKnow,
                            classes: userData.classes,
                            photoURL: image,
                            role: userData.role,
                            uid: user.uid,
                            createdAt: userData.createdAt,
                            updatedAt: new Date().toISOString(),
                            activeStatus: userData.activeStatus,
                            isNewUser: userData.isNewUser,
                        });

                        openNotificationWithIcon('success');
                        setComponentDisabled(true);
                        setIsLoading(false);
                    } else {
                        const storageRef = ref(storage, `users/${user.uid}/profile`);
                        const uploadTask = uploadBytesResumable(storageRef, photoURL);

                        setIsUploading(true);

                        uploadTask.on('state_changed',
                            (snapshot) => {
                                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                setUploadProgress(progress);
                            },
                            (error) => {
                                console.log(error);
                            },
                            async () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    image = downloadURL;
                                })

                                await updateProfile(auth.currentUser, {
                                    displayName: fullname,
                                    photoURL: image,
                                });

                                while (image === "") {
                                    await new Promise((resolve) => setTimeout(resolve, 1000));
                                }

                                // firestore update
                                await setDoc(doc(db, "users", user.uid), {
                                    fname: fname,
                                    lname: lname,
                                    fullname: fullname,
                                    email: userData.email,
                                    address: address,
                                    contact: contact,
                                    school: school,
                                    district: district,
                                    attempt: attempt,
                                    physicsResult: physicsResult,
                                    stream: stream,
                                    indexNumber: userData.indexNumber,
                                    howDidYouKnow: userData.howDidYouKnow,
                                    classes: userData.classes,
                                    photoURL: image,
                                    role: userData.role,
                                    uid: user.uid,
                                    createdAt: userData.createdAt,
                                    updatedAt: new Date().toISOString(),
                                    activeStatus: userData.activeStatus,
                                    isNewUser: userData.isNewUser,
                                });
                                openNotificationWithIcon('success');
                                setComponentDisabled(true);
                                setIsLoading(false);

                                setIsUploading(false);
                                setUploadProgress(0);
                            }
                        );
                    }
                } else {
                    // firestore update
                    const docRef = doc(db, "users", user.uid);
                    await setDoc(docRef, {
                        fname: fname,
                        lname: lname,
                        fullname: fullname,
                        email: userData.email,
                        address: address,
                        contact: contact,
                        school: school,
                        district: district,
                        attempt: attempt,
                        physicsResult: physicsResult,
                        stream: stream,
                        indexNumber: userData.indexNumber,
                        howDidYouKnow: userData.howDidYouKnow,
                        classes: userData.classes,
                        photoURL: image,
                        role: userData.role,
                        uid: user.uid,
                        createdAt: userData.createdAt,
                        updatedAt: new Date().toISOString(),
                        activeStatus: userData.activeStatus,
                        isNewUser: userData.isNewUser,
                    });
                    openNotificationWithIcon('success');
                    setComponentDisabled(true);
                    setIsLoading(false);
                }


            } catch (error) {
                openNotificationWithIcon('error');
                setIsLoading(false);
                console.log(error);
            }
        }
    }


    return (
        <Drawer
            title="User Profile"
            placement="right"
            size="large"
            onClose={onClose}
            open={isOpen}
            extra={
                <Space>
                    <Button onClick={handleDrawerClose}>Cancel</Button>
                    <Button type="primary"
                        onClick={
                            () => {
                                if (componentDisabled) {
                                    setComponentDisabled(false);
                                } else {
                                    setComponentDisabled(true);
                                }
                            }
                        }>
                        {componentDisabled ? "Edit Profile" : "Dismiss"}
                    </Button>
                </Space>
            }
        >
            <Spin
                spinning={isLoading}
                tip={
                    isUploading ? `Uploading ${uploadProgress}%` : "Please wait..."
                }
            >
                {contextHolderMessage}
                {contextHolder}
                <Form
                    layout="vertical"
                    disabled={componentDisabled}
                    ref={formRef}
                    onFinish={onSubmit}
                    onFinishFailed={onFinishFailed}
                >
                    <Row
                        style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "30px",
                        }}
                    >
                        <p>
                            Index Number: <span
                                style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#1890ff",
                                }}
                            >
                                {userData && userData.indexNumber}
                            </span>
                        </p>
                    </Row>
                    <Form.Item valuePropName="fileList"
                        style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ImgCrop rotationSlider>
                            <Badge
                                // capitalize the first letter
                                count={userData && userData.activeStatus.charAt(0).toUpperCase() + userData.activeStatus.slice(1)}
                                //color based on the active pending and hold and reject
                                color={
                                    userData && userData.activeStatus === "active" ? "green" :
                                        userData && userData.activeStatus === "pending" ? "blue" :
                                            userData && userData.activeStatus === "hold" ? "orange" :
                                                userData && userData.activeStatus === "reject" ? "red" : "grey"
                                }
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-circle"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={onChange}
                                    multiple={false}
                                    beforeUpload={() => false}
                                // onRemove={() => {
                                //     // 
                                // }}
                                >
                                    {componentDisabled ? null : uploadButton}
                                </Upload>
                            </Badge>
                        </ImgCrop>

                        <Modal
                            open={previewOpen}
                            title="Profile Picture"
                            footer={null}
                            onCancel={handleCancel}>
                            <img
                                alt="example"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </Form.Item>

                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="fname"
                                label="First Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="lname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your last name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="fullname"
                                label="Full Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="address"
                                label="Home Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your home address!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Contact Number"
                                name="contact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your contact number!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || /^0[0-9]{9}$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="District"
                                name="district"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your district!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a Discrict"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={
                                        Discricts.map((district) => (
                                            {
                                                id: district,
                                                value: district,
                                                label: district,
                                            }
                                        ))
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="school"
                                label="School"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your school!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="stream"
                                label="Your subject stream"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your subject stream!',
                                    },
                                ]}
                            >
                                <Select
                                    labelInValue
                                    options={[
                                        {
                                            value: 'maths',
                                            label: 'Maths',
                                        },
                                        {
                                            value: 'bio',
                                            label: 'Bio',
                                        },
                                        {
                                            value: 'ict',
                                            label: 'ICT',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row span={24} gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="attempt"
                                label="Your Attempt Shy in 2023"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your Attempt Shy in 2023!',
                                    },
                                ]}
                            >
                                <Select
                                    labelInValue
                                    options={[
                                        {
                                            value: '1',
                                            label: '1st',
                                        },
                                        {
                                            value: '2',
                                            label: '2nd',
                                        },
                                        {
                                            value: '3',
                                            label: '3rd',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Your result for physics in last shy"
                                name="physicsResult"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your result for physics in last shy!',
                                    },
                                ]}
                            >
                                <Select
                                    labelInValue
                                    options={[
                                        {
                                            value: 'A',
                                            label: 'A',
                                        },
                                        {
                                            value: 'B',
                                            label: 'B',
                                        },
                                        {
                                            value: 'C',
                                            label: 'C',
                                        },
                                        {
                                            value: 'S',
                                            label: 'S',
                                        },
                                        {
                                            value: 'F',
                                            label: 'F',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row span={24} gutter={10}>
                        <Col >
                            <Form.Item >
                                <Button
                                    type="primary"
                                    htmlType="submit" o
                                    nClick={onSubmit}
                                >
                                    Update Profile
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Drawer>
    );
};

export default EditUserDrawer;
